import React, { useState } from "react";
import ThemeToggle from "./ThemeToggle"
import Logo from "./Logo";
import MenuIcon from "@mui/icons-material/Menu";
import { Person } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import items from '../items.json'
import { useTheme } from "@emotion/react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  AppBar,
  Container,
  IconButton, 
  Link, 
  Popover,
  Drawer,
  Toolbar
} from "@mui/material";

function Header() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);

  const theme = useTheme();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const drawerWidth = "13dvw";

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setHoveredSection(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredSection(null);
  };

  return (
      <AppBar
        component='header'
        position="relative"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          pt: 2,
          transition: theme.transitions.create(["width", "padding"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          zIndex: 1500,
          ...(open && {
            paddingLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "padding"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Container
          id="hyuio"
          maxWidth="lg"
          sx={{
            ...(open && {
              paddingLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth}px)`,
              transition: theme.transitions.create(["width", "padding"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }),
          }}
        >
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              ...(open && {
                pl: { sm: 0, xs: 0 },
                pr: { sm: 0, xs: 0 },
              }),
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow: theme.customShadows.primary,
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                px: 0,
              }}
            >
              <Link sx={{ mr: 10, flexShrink: 0 }} component={NavLink} to="/"   aria-label="home-page">
                <Logo />
              </Link>

              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  position: "relative",
                }}
              >
                {items.sections.map((section, index) => (
                  <Box
                    key={index}
                    sx={{ p: 1, flexShrink: 0, position: "relative" }}
                    onMouseEnter={(e) => handlePopoverOpen(e, index)}
                    onMouseLeave={handlePopoverClose}
                  >
                    <Link
                      color="text.primary"
                      noWrap
                      underline="none"
                      component={NavLink}
                      variant="body2"
                      to={`${section.url}`}
                      sx={{
                        display: "block",
                        mb: section.types.length > 0 ? 1 : 0,
                      }}
                    >
                      {section.title}
                    </Link>
                    {section.types.length > 0 && hoveredSection === index && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "100%",
                          p: 2,
                          left: 0,
                          bgcolor: "background.paper",
                          boxShadow: theme.customShadows.primary,
                          borderRadius: "10px",
                          zIndex: 1,
                        }}
                      >
                        <List aria-labelledby="nested-list-subheader">
                          {section.types.map((type, subIndex) => (
                            <ListItem key={subIndex} sx={{ p: 0 }}>
                              <Link
                                color="text.secondary"
                                noWrap
                                underline="none"
                                component={NavLink}
                                variant="body2"
                                to={type.url}
                                sx={{ pl: 2 }}
                              >
                                <ListItemButton
                                  sx={{
                                    "&:hover": {
                                      bgcolor: "primary.lighter",
                                    },
                                  }}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography
                                        variant="body2"
                                        sx={{ pl: "5px", fontWeight: "normal" }}
                                      >
                                        {type.title}
                                      </Typography>
                                    }
                                  />
                                </ListItemButton>
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              id="icon-box"
              sx={{
                position: "static",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <ThemeToggle />
              <IconButton component="a" href="/app/dashboard" target="_blank" aria-label="app-page">
                <Person />
              </IconButton>
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <IconButton
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", ml: "5px" }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                component="nav"
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                sx={{
                  width: `calc(100% - ${drawerWidth}px)`,
                }}
              >
                <Box
                  id="drawerb"
                  sx={{
                    p: 3,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                    py: 8,
                  }}
                >
                  {items.sections.map((section, index) => (
                    <Box
                      onMouseEnter={(e) => handlePopoverOpen(e, index)}
                      onMouseLeave={handlePopoverClose}
                      key={index}
                      sx={{
                        p: 1,
                        flexShrink: 0,
                        position: "relative",
                       
                      }}
                    >
                      <Link
                        color="text.primary"
                        noWrap
                        underline="none"
                        component={NavLink}
                        variant="body2"
                        to={`${section.url}`}
                        sx={{
                          display: "block",
                          mb: section.types.length > 0 ? 1 : 0,
                        }}
                      >
                        {section.title}
                      </Link>
                      {section.types.length > 0 && (
                       
                          <Popover
                            open={hoveredSection === index}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <List aria-labelledby="nested-list-subheader">
                              {section.types.map((type, subIndex) => (
                                <ListItem key={subIndex} sx={{ p:'5px' }}>
                                  <Link
                                    color="text.secondary"
                                    noWrap
                                    underline="none"
                                    component={NavLink}
                                    variant="body2"
                                    to={type.url}
                                    sx={{ pl: 1 }}
                                  >
                                    <ListItemButton
                                      sx={{
                                        "&:hover": {
                                          bgcolor: "primary.lighter",
                                        },
                                      }}
                                    >
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              pl: "5px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {type.title}
                                          </Typography>
                                        }
                                      />
                                    </ListItemButton>
                                  </Link>
                                </ListItem>
                              ))}
                            </List>
                          </Popover>
                      
                      )}
                    </Box>
                  ))}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
  );
}

export default React.memo(Header);
