import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const setSessionStorageItem = (key, value) => {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
    sessionStorage.setItem(key, encryptedValue); 
};

export const getSessionStorageItem = (key) => {
    const encryptedValue = sessionStorage.getItem(key); 
    if (encryptedValue) {
        const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedValue);
    }
    return null;
};

export const removeSessionStorageItem = (key) => {
    sessionStorage.removeItem(key); // Use sessionStorage instead of localStorage
};