import React, {
    useState,
    useEffect,
    createContext,
    useCallback,
    useContext,
  } from "react";
  // eslint-disable-next-line
  import { login, isAuthenticated, API_URL } from "../API/constant";
  import axios from "axios";
  import {
    // eslint-disable-next-line
    setSessionStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
  } from "./sessionStorage";
import Login from "../pages/Login";
import PropTypes from "prop-types";


  const AuthContext = createContext({});
  const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [name, setName] = useState(null);
    const [error, setError] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    const [selectedStoreId, setSelectedStoreId] = useState(null); //4
    const [storeOwner, setStoreOwner] = useState(false); // Initialize with appropriate initial value
    // const [stores, setStores] = useState(null); // 1
    const istoken = { getSessionStorageItem };
    const [clockInTime, setClockInTime] = useState(null); // State to hold clock-in time
    const [clockOutTime, setClockOutTime] = useState(null);
    const [ipAddress, setIPAddress] = useState('')
    // eslint-disable-next-line
    
    const clearVariables = () => {
      removeSessionStorageItem("storeName");
      removeSessionStorageItem("user");
      removeSessionStorageItem("token");
      removeSessionStorageItem("role");
      removeSessionStorageItem("storeId");
      setUser(null);
      setToken(null);
      setUserRole(null);
      setShowPopup(false);

      setSelectedStoreId(null);
    }
  

  
    
    const isTokenExpired = useCallback((token) => {
      if (!token || typeof token !== "string") {
        return true; // If token is not provided or not a string, consider it expired
      }
  
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
        return Date.now() > expirationTime;
      } catch (error) {
        clearVariables();
        return true; // Consider token expired in case of any errors
      }
    },[]);
  
  
      
  // token failed
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired(token) || !token) {
        setShowPopup(true);
        clearVariables()
      } else {
        setShowPopup(false);
      }
    };
  
    if (token) {
      checkTokenExpiration();
    }
  }, [token, isTokenExpired]);

  
  
  
    const handleLogin = async(username, password) => {
    setIsLoading((prevLoading) => !prevLoading);
    try {
      const response = await axios.post(`${API_URL}/users/login`, {
        username: username,
        password: password,
      
      });
  
      if (response && response.data) {
        const user = response.data.response;
        // console.log('resd2', response.data.response)
        // console.log('resd1', response.data)
        const { isStoreOwner, store } = user; // Destructure values directly from user object
  
  
      
        setUser(user);
        setUserRole(user.role);
        setToken(user.token);
        setName(user.username);
        setStoreOwner(isStoreOwner);
        // console.log("no error here aith")
        await login(user, user.token, user.role, store); // Assuming login function accepts user, token, role, and store
        setIsLoading(false);
        return response.data;
      } else{
        return response.data.error;
      }
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred.");
      // console.log('Error:', error);
      return error.response?.data || { error: "An error occurred." };
    } finally {
      setIsLoading(false);
    }
    };
        
  
  
  
  
    
    return (
      <AuthContext.Provider
        value={{
          user,
          name,
          token,
          userRole,
          error,
          setError,
          setUser,
          setUserRole,
          setToken,
          handleLogin,
        
          setIsLoading,
          isLoading,
          istoken,
        
       
          selectedStoreId,
          setSelectedStoreId,
        
          storeOwner,
          clockInTime,
          setClockInTime,
          clockOutTime,
          setClockOutTime,
          ipAddress, 
          setIPAddress,
        
        }}
      >
         {isAuthenticated() ? (
            <>
            {children}
            </>
         ):(
            <Login />
         )}
      </AuthContext.Provider>
    );
  };
  
  const useAuth = () => useContext(AuthContext);
  
  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  
  export { AuthProvider, useAuth };