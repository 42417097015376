import  { useState, useEffect } from 'react';
import axios from 'axios';
import {GiWindsock} from 'react-icons/gi'
import { WiThermometer, WiHumidity, WiDaySunny, WiCloudy, WiRain, WiSnowWind, WiThunderstorm, WiFog } from 'react-icons/wi'
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography,  } from '@mui/material';

const WeatherIcon = ({ iconCode }) => {
  // Define icons corresponding to different weather conditions
  const iconMap = {
    '01d': <WiDaySunny size={30} />,
    '01n': <WiDaySunny size={30}  />,
    '02d': <WiCloudy size={30} />,
    '02n': <WiCloudy size={30} />,
    '03d': <WiCloudy size={30} />,
    '03n': <WiCloudy size={30} />,
    '04d': <WiCloudy size={30} />,
    '04n': <WiCloudy size={30} />,
    '09d': <WiRain size={30} />,
    '09n': <WiRain size={30} />,
    '10d': <WiRain size={30} />,
    '10n': <WiRain size={30} />,
    '11d': <WiThunderstorm size={30} />,
    '11n': <WiThunderstorm size={30} />,
    '13d': <WiSnowWind size={30} />,
    '13n': <WiSnowWind size={30} />,
    '50d': <WiFog size={30}/>,
    '50n': <WiFog size={30}  />,
    
  };

  // Return the corresponding icon based on the icon code
  return iconMap[iconCode] || null;
};


const WEATHERAPI = 'https://weather.enigmance.com'
const IPLOCATION = 'https://ip.enigmance.com'


function Weather() {
  // eslint-disable-next-line
  const [currentLocation, setCurrentLocation] = useState(null);
  const [weatherCondition, setWeather] = useState(null);


  useEffect(() => {
    const getLocation = async () => {
      try {
        // Fetch IP location
        const ipResponse = await axios.get(`${IPLOCATION}`);
        
        // Extract latitude and longitude directly from IP location response
        const { latitude, longitude } = ipResponse.data;
  
        // Fetch weather data using the obtained latitude and longitude
        const response = await axios.get(`${WEATHERAPI}?lat=${latitude}&lon=${longitude}`);
        
        // Set weather and current location state variables
        setWeather(response.data);
        setCurrentLocation(response.data);
      } catch (error) {
        console.error('Error fetching weather:', error);
      }
    };
  
    getLocation();
  }, []); // Empty dependency array since we don't want this effect to depend on any state variables

// convert temp to celsius and round to 1 decimal
const temp = weatherCondition?.main?.temp - 273.15
const tempCelsius = temp.toFixed(0)
const windspeed = weatherCondition?.wind?.speed
const humidity = weatherCondition?.main?.humidity
const tempIcon = <WiThermometer size={20} />
const windIcon = <GiWindsock size={20} />
const humidityIcon = <WiHumidity size={20} />




var icon = weatherCondition?.weather[0]?.icon


return (
  <Box sx={{p:2}}>
    <Typography component='span' variant="h5" fontWeight={600}>weather</Typography>
      {weatherCondition && (
         <List dense={true} disablePadding={true}>
          <ListItem disablePadding={true} disableGutters={true}>
          <ListItemIcon>
            <WeatherIcon iconCode={icon} />
            </ListItemIcon>
            <ListItemText primary={`${weatherCondition.weather[0].main} in ${currentLocation.name}`} />
          </ListItem>
          <ListItem disablePadding>
          <ListItemIcon>
                    {tempIcon}
                  </ListItemIcon>
            <ListItemText primary={`${tempCelsius}°C`} />
          </ListItem>
          
            <ListItem disablePadding>
                  <ListItemIcon>
                    {humidityIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${humidity}%`}
                   
                  />
                </ListItem>
                <ListItem disablePadding>
                <ListItemIcon>
                    {windIcon}
                  </ListItemIcon>
            <ListItemText primary={`${windspeed}%`} />
          </ListItem>
        </List>
      )}
    </Box>
);
}

export default Weather

