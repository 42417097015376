import createCache from '@emotion/cache';
import { v4 as uuidv4 } from 'uuid';


const isBrowser = typeof document !== 'undefined';
// Use btoa() to encode the UUID into a base64 string
const nonce = btoa(uuidv4());

export default function createEmotionCache() {
  let insertionPoint;
  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
    insertionPoint = emotionInsertionPoint ?? undefined;
  }

  return createCache({ 
    // key: 'css',
    // nonce: nonce,
    // prepend: true,
    key: 'mui-style', 
    nonce: nonce,
    insertionPoint
  });
}
