

import React from 'react'
import Login from '../pages/Login';
import AppLayout from '../components/AppLayout';
import { AuthProvider } from '../Context/Authcontext';

const AppRoutes = {
    path: '/app',
    element: 
    <AuthProvider>
    <AppLayout />
    </AuthProvider>,
    children: [
      {
        path: 'dashboard',
        element: <Login />
      },
      
    ]
  };
  
  export default AppRoutes;
