//
import Card from './Card';
import ListItem from './List';
import Paper from './Paper';
import Table from './Table';
import Button from './Button';
import Toast from './Toast';
import Container from './Container';
import Backdrop from './Backdrop';
import Typography from './Typography';
import Autocomplete from './Autocomplete';
import ListItemButton from './ListItemButton';
// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Card(theme),
    Table(theme),
    Paper(theme),
    Button(theme),
    Toast(theme),
    Container(theme),
    ListItem(theme),
    Backdrop(theme),
    Typography(theme),
    Autocomplete(theme),
    ListItemButton(theme)

  );
}
