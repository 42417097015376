import { lazy, Suspense } from 'react';
import { CircularProgress, Box } from "@mui/material";
import MainLayout from '../components/MainLayout';



const About = lazy(() => import('../pages/About'));
const HMS = lazy(() => import('../pages/HMS'));
const Contact = lazy(() => import('../pages/Contact'));
const Blog = lazy(() => import('../pages/Blog'));
const Services = lazy(() => import('../pages/Services'));
const Home = lazy(() => import('../pages/Home'));
const Blog2023 = lazy(() => import('../pages/Blog2023'));
const BlogShould = lazy(() => import('../pages/BlogShould'));
const BlogWhy = lazy(() => import('../pages/BlogWhy'));
const BlogSocial = lazy(() => import('../pages/BlogSocial'));
const POS = lazy(() => import('../pages/POS'));
const Privacy = lazy(() => import('../pages/Privacy'));
const Knowledge = lazy(() => import('../pages/Knowledge'));
const Terms = lazy(() => import('../pages/Terms'));
const ThreeScene = lazy(() => import('../components/Portofolio'));
const Industries = lazy(() => import('../pages/Industries'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const BlockPageContent = lazy(() => import('../pages/BlogPageContent'));
const PMS = lazy(() => import('../pages/PMS'));

function Loader () {
  return (
    <Box
      sx={{
        display:'flex',
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: '100vh',
      }}
    >
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "47%",
          color: "primary",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  );
}
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <Suspense fallback={<Loader />}>
          <Home />
        </Suspense>
      )
    },
    {
      path: 'about',
      element: (
        <Suspense fallback={<Loader />}>
          <About />
        </Suspense>
      )
    },
    {
      path: 'services',
      element: (
        <Suspense fallback={<Loader />}>
          <Services />
        </Suspense>
      )
    },
    {
      path: 'contact',
      element: (
        <Suspense fallback={<Loader />}>
          <Contact />
        </Suspense>
      )
    },
    {
      path: 'blog',
      element: (
        <Suspense fallback={<Loader />}>
          <Blog />
        </Suspense>
      )
    },
    {
      path: '2023 Digital Marketing Trends to Take Advantage of Now',
      element: (
        <Suspense fallback={<Loader />}>
          <Blog2023 />
        </Suspense>
      )
    },
    {
      path: 'Should You Choose Public Relations Or Content Marketing For Your Business',
      element: (
        <Suspense fallback={<Loader />}>
          <BlogShould />
        </Suspense>
      )
    },
    {
      path: 'Why Your Business Should Not Be On Every Social Media Platform',
      element: (
        <Suspense fallback={<Loader />}>
          <BlogWhy />
        </Suspense>
      )
    },
    {
      path: 'Social Media Marketing What It Is, How It Works, Pros And Cons',
      element: (
        <Suspense fallback={<Loader />}>
          <BlogSocial />
        </Suspense>
      )
    },
    {
      path: 'knowledge',
      element: (
        <Suspense fallback={<Loader />}>
          <Knowledge />
        </Suspense>
      )
    },
    {
      path: '/blog/:id',
      element: (
        <Suspense fallback={<Loader />}>
          <BlockPageContent />
        </Suspense>
      )
    },
    {
      path: 'portofolio',
      element: (
        <Suspense fallback={<Loader />}>
          <ThreeScene />
        </Suspense>
      )
    },
    {
      path: '/solutions/point-of-sale-system',
      element: (
        <Suspense fallback={<Loader />}>
          <POS />
        </Suspense>
      )
    },
    {
      path: '/solutions/hotel-management-system',
      element: (
        <Suspense fallback={<Loader />}>
          <HMS />
        </Suspense>
      )
    },
    {
      path: '/solutions/property-management-system',
      element: (
        <Suspense fallback={<Loader />}>
          <PMS />
        </Suspense>
      )
    },
    {
      path: 'privacy policy',
      element: (
        <Suspense fallback={<Loader />}>
          <Privacy />
        </Suspense>
      )
    },
    {
      path: 'industries',
      element: (
        <Suspense fallback={<Loader />}>
          <Industries />
        </Suspense>
      )
    },
    {
      path: 'terms',
      element: (
        <Suspense fallback={<Loader />}>
          <Terms />
        </Suspense>
      )
    },
    {
      path: '*',
      element: (
        <Suspense fallback={<Loader />}>
          <ErrorPage />
        </Suspense>
      )
    }
  ]
};

export default MainRoutes;
