import React from "react";
import {
  Snackbar,
  Alert,
  Slide,
} from "@mui/material";

function TransitionLeft(props) {
  return <Slide {...props} direction="up" />;
}

const CustomSnackbar = ({
  error,
  successMessage,
  open,
  handleSnackbarClose,
  transition,
}) => {
  if (error) {
    return (
      <Snackbar
        style={{ zIndex: 1500, width: "50%", wordBreak: "break-word" }}
        open={open}
        onClose={handleSnackbarClose}
        TransitionComponent={transition}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    );
  }

  if (successMessage) {
    return (
      <Snackbar
        open={open}
        onClose={handleSnackbarClose}
        TransitionComponent={transition}
        style={{ zIndex: 1500, width: "50%", wordBreak: "break-word" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          // sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export { CustomSnackbar, TransitionLeft };
