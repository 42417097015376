// import axios from 'axios';
import {setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem} from '../Context/sessionStorage';
// export const API_URL = 'https://server.aishadrycleaner.com/api'
export const API_URL = `${process.env.REACT_APP_HOST}/api`




export const login = async (user, token, userRole, store) => {
  try {
      setSessionStorageItem('user', user);
      setSessionStorageItem('token', token);
      setSessionStorageItem('role', userRole);   
      if (!user.isStoreOwner) {
        setSessionStorageItem('storeName', store);   
      }
    return 
  } catch (error) {
    // console.log("error constants", error)
    throw new Error(error);
  }
};




export const logout = () => {
  removeSessionStorageItem('token');
  removeSessionStorageItem('user');
  removeSessionStorageItem('role');
  removeSessionStorageItem('store');

};

export const isAuthenticated = () => {
  return getSessionStorageItem('user') !== null;
}

