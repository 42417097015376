import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Snackbar,
  Slide,
  Button,
  TextField,
  Grid,
  Box,
  Alert,
  Container,
  CircularProgress,
  InputAdornment,
  Tabs,
  IconButton,
  Tab,
  Link
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// import Forgot from "./ForgotPassword";
import ThemeProvider from "../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { TabPanel, a11yProps } from "../utils/BasicTab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../components/Logo";


function TransitionLeft(props) {
  return <Slide {...props} direction="up" />;
}
function Copyright(props) {
  return (
    <Box variant="body2" color="text.secondary" align="center" {...props}>
      {'© enigmance inc., ' + new Date().getFullYear() + ' '}
      <Link color="inherit" 
      sx={{textDecoration:'none'}}
      href="https://enigmance.com/" target="_blank">
       
      </Link>
    </Box>
  );
}

export default function Login() {
  // eslint-disable-next-line
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [value, setValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleLogin = () =>{
    
  }


  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleValidation = () => {
    setError(null); // Reset error state

    if (!username || !password) {
      setOpen(true);
      setTransition(transition);
      setError("Please enter both username and password");
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }

    setLoading(true);

    try {
      
      const response = await handleLogin(username, password);
      if(response.data){
        setLoading(false);
       return
      }
      else{
        setLoading(false);
        setError(response.error)
        setOpen(true)
        setTransition(() => TransitionLeft);
      }
     
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
        setLoading(false);
        setOpen(true);
        setTransition(() => TransitionLeft);
      } else {
        setError("An error occurred while logging in.");
        setOpen(true);
        setTransition(() => TransitionLeft);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <ThemeProvider theme={theme}>
     <Container component="main" maxWidth="xs" sx={{
      display: 'flex',
      width: "100%",
      justifyContent: 'center', // Aligns the container horizontally at the center
      alignItems: 'center',
      flexDirection:'column',
      minHeight: '100vh', // Ensures the container fills the height of the viewport
    }}>
          <Tabs
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              // marginTop: "50px",
              padding: "1.25rem 1.25rem",
              position: "relative",
              width: "100%",
              ml: "auto",
              mr: "auto",
              spacing: 3,
              alignItems: "center",
              justifyContent: "center",
            }}
            centered
          >
            <Tab label="Sign In" {...a11yProps(0)} />
            {/* <Tab label="Forgot Password" {...a11yProps(1)} /> */}
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            sx={{
              boxShadow: 0,
            }}
          >
            <Box
              sx={{
                // marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
               <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
          <Logo />
        </Box>
  
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" 
                // autoComplete={process.env.REACT_APP_NODE_ENV !== 'production' ? 'on' : 'off'}
                
                onSubmit={onSubmit} noValidate sx={{ mt: 1 }}
              
              >
                <TextField
                  sx={{ mb: 3 }}
                  fullWidth
                  autoFocus
                  label="username"
                  required
                  name="username"
                  autoComplete={process.env.REACT_APP_NODE_ENV !== 'production' ? 'on' : 'off'}                  
                  value={username}
                  id="username"
                  onChange={onChangeUsername}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
  
                <TextField
                  label="password"
                  required
                  name="password"
                  autoComplete={process.env.REACT_APP_NODE_ENV !== 'production' ? 'on' : 'off'}
                  value={password}
                  id="password"
                  onChange={onChangePassword}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
  
                <br />
                <br />
  
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  align="center"
                  width="100%"
                  disabled={loading}
                  endIcon={loading && <CircularProgress size={20} />}
                >
                  {loading ? "Loading..." : "Login"}
                </Button>
                {error && (
                  <Snackbar
                    open={open}
                    onClose={handleSnackbarClose}
                    TransitionComponent={transition}
                  >
                    <Alert
                      onClose={handleSnackbarClose}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {error}
                    </Alert>
                  </Snackbar>
                )}
                <br />
                    <Copyright />
                <Grid container>
                  {/* {user.isStoreOwner ? (
                    <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            
            >
                <p>welcome</p>
            </Box>
  
                  )} */}
                </Grid>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            sx={{
              boxShadow: 0,
            }}
          >
            {/* <Forgot /> */}
          </TabPanel>
         
      
        
      </Container>
       
      
    
    </ThemeProvider>
  );
}
