import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { useTheme } from '@mui/material/styles';
import BackToTop from './BackToTop'

const MainLayout = () => {
  const theme = useTheme();
  return (
    <Box
        sx={{
          width: '100%',
          display:'flex',
          flexDirection:'column',
          p: '0px',
          bgcolor: theme.palette.background.default,
        }}
      >
      <Header /> 
      <Box
        component="main" 
        sx={{
          width: '100%',
          flexGrow: 1,
          display:'flex',
          p: '0px',
          bgcolor: theme.palette.background.default,
        }}
      >
        <Outlet /> 
        <BackToTop />
      </Box>
      <Footer /> 
    </Box>
  );
};

export default React.memo(MainLayout);
