import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline  } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import ThemeRoutes from './routes';
import ThemeProvider from './theme'
import createEmotionCache from './theme/createEmotionCache';
import NoInternetConnection from './components/NoInternetConnection';

const cache = createEmotionCache();

export default function App() {
  return (
    <NoInternetConnection>
      <HelmetProvider>
        <Helmet>
        <link rel="preconnect" href="https://weather.enigmance.com" />
        <link rel="preconnect" href="https://ip.enigmance.com" />
          <meta
            httpEquiv="Content-Security-Policy"
            content={`
              default-src 'self';
              script-src 'self' https://weather.enigmance.com https://ip.enigmance.com;
              connect-src 'self' https://weather.enigmance.com https://ip.enigmance.com;
              img-src 'self' https://enigmance.com;
              media-src 'self' https://enigmance.com;
              style-src 'self' 'nonce-${cache.nonce}'; 
              font-src 'self' data:;
              base-uri 'self';
            `}
          />
        </Helmet>
        <BrowserRouter>
        <CacheProvider value={cache}>
          <ThemeProvider>
          <CssBaseline />
            <ThemeRoutes />
          </ThemeProvider>
          </CacheProvider>
        </BrowserRouter>
      </HelmetProvider>
    </NoInternetConnection>
  );
}
