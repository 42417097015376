import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { DarkThemeProvider } from './Context/ThemeContext';
import * as serviceWorker from './serviceWorker';
import '@fontsource/roboto/300.css'; // Light
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DarkThemeProvider>
      <App />
    </DarkThemeProvider>
  </React.StrictMode>
);

// function logWebVitals(metric) {
//     console.log(metric); // Logs all metrics (FCP, CLS, TTFB, etc.)
  
//     // Specifically log Largest Contentful Paint (LCP)
//     if (metric.name === 'LCP') {
//       console.log(`Largest Contentful Paint (LCP): ${metric.value}ms`);
//     }
//     // if (metric.name === 'TBT') {
//     //   console.log(`Total Blocking Time (TBT): ${metric.value}ms`);
//     // }
//   }
  
//   // Pass the custom logging function to reportWebVitals
// reportWebVitals(logWebVitals);

serviceWorker.register();
