// ----------------------------------------------------------------------

export default function Container(theme) {
    return {
      MuiContainer: {
        styleOverrides: {
          root: {
        position: 'relative',
           padding:2,
           width:'100%',
    
            zIndex: 0, // Fix Safari overflow: hidden with border radius
          },
        },
      },
    };
  }
  