import React, { useState } from "react";
import axios from "axios";
import { CustomSnackbar, TransitionLeft } from './Alert';
import{CircularProgress, Stack, TextField, Button}from "@mui/material";



function Newsletter() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [transition, setTransition] = useState(undefined);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleValidation = () => {
    setError(null); // Reset error state
    if (!emailRegex.test(email)) {
      // Show error message or handle empty fields
      setOpen(true);
      setTransition(transition);
      setError("Invalid email");
      return false;
    }

    return true;
  };


  const onSubmit = (e) => {
    e.preventDefault();

      if (!handleValidation()) {
      return;
    }
    setLoading(true)
    setTimeout(() => {
    setEmail("")
    setLoading(false)
    setSuccess("you've subscribed successfully");
    setOpen(true)
    setTransition(transition)
  }, 3000);

  };
  



  const handleSubmit = async(e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/api/newsletter`,
        {
          email: email

        },
     
      );

      if (response.data) {
        setLoading(false);
        setOpen(true);
        setSuccess(response.data.message)
        setTransition(transition)
      }
    } catch (error) {
      setError(error.response.data.error);
      setLoading(false);
      setOpen(true);
      setTransition(transition)
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

    
  const handleSnackbarClose = () => {
    setOpen(false);
  };


  return (

    <Stack direction="row" spacing={1} useFlexGap>
    <TextField
      size="small"
      fullWidth
      value={email}
      type="email"
      label="Email"
      onChange={handleChange}
      sx={{mr:2}}
      
    />
    <Button 
    disabled={loading}
    onClick={process.env.REACT_APP_NODE_ENV === 'production' ? handleSubmit: onSubmit}
    endIcon={loading && <CircularProgress size={16} />}
    variant="contained" 
    color="primary" 
    sx={{ flexShrink: 0 }}>
       {loading ? "Loading..." : " Subscribe"}
     
    </Button>
    <CustomSnackbar
      error={error}
      open={open}
      transition={TransitionLeft}
      successMessage={success}
      handleSnackbarClose={handleSnackbarClose} />
  </Stack>

  );
}

export default Newsletter;
