// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles(mode) {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      
      styles={{
        '*': {
          boxSizing: 'border-box',
          // backgroundColor:"inherit",
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          backgroundColor:"inherit",
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          '&::-webkit-scrollbar': {
            width: '2px',
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white', // Change to your desired color
            borderRadius: '2px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'inherit',
            borderRadius: '5px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
        
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        li:{
          margin:0,
          padding:0,
          marginTop:0,
          marginBottom:0
        }
      }}

    />
  );

  return inputGlobalStyles;
}
