import * as React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {Fab, Box, Tooltip, Fade} from '@mui/material';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';



export default function BackToTop() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });

  const handleClick = () => {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
    const behavior = prefersReducedMotion.matches ? 'auto' : 'smooth';

    window.scrollTo({ top: 0, behavior });
    setOpen(false);
  };

  return (
    <Fade in={trigger}>
      <Tooltip title="Scroll to top" open={open} onClose={handleClose} onOpen={handleOpen}>
        <Box
          className="mui-fixed"
          sx={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 10,
          }}
        >
          <Fab
            sx={[
              (theme) => ({
                backgroundColor: theme.palette.primary[100],
                boxShadow: `0px 4px 20px rgba(170, 180, 190, 0.3)`,
                '&:hover': {
                  backgroundColor: theme.palette.primary[200],
                },
                '&:active': {
                  boxShadow: `0px 4px 20px rgba(170, 180, 190, 0.6)`,
                },
              }),
            
            ]}
            size="small"
            aria-label='backToTop'
            onClick={handleClick}
            data-ga-event-category="docs"
            data-ga-event-action="click-back-to-top"
          >
            <KeyboardArrowUpRoundedIcon
              sx={(theme) => ({
                color:theme.palette.primary[800],
              })}
            />
          </Fab>
        </Box>
      </Tooltip>
    </Fade>
  );
}
