import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import { useDarkTheme } from '../Context/ThemeContext';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const {mode} = useDarkTheme();

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette,
        mode,
          ...(mode === "light" ? palette.light :  palette.dark),
      },
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    // <CacheProvider value={cache}>
      <MUIThemeProvider theme={theme}>
          <GlobalStyles />
          {children}
      </MUIThemeProvider>
    // </CacheProvider>
  );
}

