import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  // 100: 'rgba(255, 255, 255, 0.12)',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#6c164896',
  light: '#bc1c60',
  main: '#FF0000',
  dark: '#961043',
  darker: '#061B64',
  contrastText: '#fff',
};

const CHAT ={
  main:"#0b6bcb",
  secondary:"#0078fe",

};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: 'rgba(0, 0, 0, 0.6)',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#229A16',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: '#fff',
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#7A0C2E',
  main: '#e8403a',
  color:'#0000',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const STATES = {
  submitted: '#0AD3FF',     // Use the color code for "Gold" as an example
  sorting: '#F25F5C',   // Use the color code for "Lime Green" as an example
  cleaning: '#F694C1',     // Use the color code for "Light Salmon" as an example
  ironing: '#247BA0',      // Use the color code for "Deep Sky Blue" as an example
  packaging: '#70C1B3',    // Use the color code for "Blue Violet" as an example
  ready: '#FF6347',        // Use the color code for "Tomato" as an example
  collected: '#9370DB',    // Use the color code for "Medium Purple" as an example
  pickup: '#B744B8',    // Use the color code for "Medium Purple" as an example
};

const PAY = {
  pending: '#85C7F2',     // Use the color code for "Gold" as an example
  paid: '#499F68',   // Use the color code for "Lime Green" as an example
};

const LOGO = {
  light:'#FF0000',
  dark:'#ff1a1a',
}


const DARK_GREY = {
  0: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
};



const DARK_PRIMARY = {
  lighter: '#9E2B5F',
  light: 'rgb(243, 129, 167)',
  main:"#FF0000",
  dark: '#7B1E46',
  darker: 'rgb(168, 68, 102)',
  contrastText: '#fff',
};

const DARK_SECONDARY = {
  lighter: '#3A4B60',
  light: '#4F6B7E',
  main: DARK_GREY[400],
  dark: '#2A3949',
  darker: '#17202B',
  contrastText: '#ffcc00',
};

const DARK_PALETTE = {
  grey: DARK_GREY,
  primary: DARK_PRIMARY,
  secondary: DARK_SECONDARY,
  divider: alpha(DARK_GREY[500], 0.24),
  text: {
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  background: { 
    paper: '#424242',
    default:'#424242',
    neutral: DARK_GREY[700], // table header
  },
  action: {
    active: DARK_GREY[300],
    selected:  DARK_GREY[400],
    disabled: alpha(DARK_GREY[500], 0.8),
    disabledBackground: alpha(DARK_GREY[500], 0.24),
    focus: alpha(DARK_GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const LIGHT_GREY = {
  0: '#FFFFFF',
  // 100: 'rgba(255, 255, 255, 0.12)',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const LIGHT_PRIMARY = {
  lighter: '#FCE4EC',
  light: '#F8BBD0',
  main: '#FF0000',
  dark: '#C2185B',
  darker: '#880E4F',
  contrastText: '#fff',
};

const LIGHT_SECONDARY = {
  lighter: '#B3E0F2',
  light: '#81D4FA',
  main: '#fb7185',
  dark: '#0288D1',
  darker: '#01579B',
  contrastText: '#fff',
};

const LIGHT_PALETTE = {
  grey: LIGHT_GREY,
  primary: LIGHT_PRIMARY,
  secondary: LIGHT_SECONDARY,
  divider: alpha(LIGHT_GREY[500], 0.24),
  text: {
    primary: '#000',
    secondary: 'rgba(0, 0, 0, 0.7)',
    disabled: 'rgba(0, 0, 0, 0.5)',
  },
  background: {
    paper: '#fff',
    default: '#fff',
    neutral: LIGHT_GREY[200], // table header
  },
  action: {
    active: LIGHT_GREY[500],
    selected: LIGHT_GREY[700],
    disabled: alpha(LIGHT_GREY[500], 0.8),
    disabledBackground: alpha(LIGHT_GREY[300], 0.24),
    focus: alpha(LIGHT_GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette =  {
  mode: "default" ? "light": "dark",
  common: { black: '#000', white: '#fff' },
  light:LIGHT_PALETTE,
  dark: DARK_PALETTE, // Add the dark palette
  default:LIGHT_PALETTE,
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  logo:LOGO,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  state:STATES,
  chat:CHAT,
  payment:PAY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    // hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },

};

export default palette;
