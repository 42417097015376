import { Outlet } from 'react-router-dom';
// ==============================|| MINIMAL LAYOUT ||============================== //

const AppLayout = () => (
  <>
    <Outlet />
  </>
);

export default AppLayout;
