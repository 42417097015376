import * as React from 'react';
import {Box, Container, IconButton, Link, Typography,} from '@mui/material';
// eslint-disable-next-line
import Weather from './Weather'
import Logo from './Logo';
import { Facebook, Instagram } from '@mui/icons-material';
import TwitterIcon from '@mui/icons-material/X';
import Newsletter from './Newsletter';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link underline='none' color="text.secondary"  href="https://enigmance.com/">Enigmance inc., &nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
    component="footer"
      sx={{
        
        py: { xs: 8, sm: 10 },
        
        
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          p:'10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
          <Box sx={{ ml: '-15px' }}>
            <Link
              href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com" : '/'}
              aria-label="Go to Enigmance homepage" // Adds an accessible label to the link
            >
              <Logo alt="Enigmance logo" />  {/* alt text for the logo */}
              {/* You can add link text as well */}
              <span style={{display:'none'}}>Enigmance Homepage</span> {/* Visually hidden link text */}
            </Link>
          </Box>

            <Typography component='span' variant="h5"  fontWeight={600} gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Subscribe to our newsletter for weekly updates and promotions.
            </Typography>
            <Newsletter />
          </Box>
        </Box>
        <Weather />

        <Box
          sx={{
            display: 'flex',
            // flexDirection: 'column',
            flexDirection: { xs: 'column', sm: 'row' },
            p:2,
            gap: 1,
            mt: { xs: 1, sm: 0 },
          }}
        >
          
          <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography component='span' variant="h5"  fontWeight={600}>
            Company
          </Typography>
          <Link
           underline='none'
          color="text.secondary" 
          // component={NavLink}
          //  href="/about"
          href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/about" : '/about'}
           >
            About us
          </Link>
          <Link 
           underline='none'
          color="text.secondary" 
          // component={NavLink}
          //  href="/blog"
           href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/blog" : '/blog'}
          
          >
            blog
          </Link>
          <Link 
           underline='none'
          color="text.secondary" 
          // component={NavLink}
          //  href="/industries"
           href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/industries" : '/industries'}

          >
            industries
          </Link>

          <Link 
           underline='none'
          color="text.secondary"
          // // component={NavLink}
          //  href="/knowledge"
           href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/knowledge" : '/knowledge'}

           >
            knowledge
          </Link>
        </Box>
        </Box>
        
        
        <Box
           sx={{
            display: 'flex',
            flexDirection: 'column',
            p:2,
            gap: 1,
            mt: { xs: 1, sm: 0 },
           }}
        >
          <Typography component='span' variant="h5" fontWeight={600}>
            Legal
          </Typography>
          <Link 
           underline='none'
          color="text.secondary" 
          // // component={NavLink}
          //  href="/terms"
           href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/terms" : '/terms'}

          >
            Terms
          </Link>
          <Link 
           underline='none'
          color="text.secondary" 
          // component={NavLink}
          //  href="/privacy policy"
           href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/privacy policy" : '/privacy policy'}

          >
            Privacy
          </Link>
          <Link 
           underline='none'
          color="text.secondary"
          // component={NavLink}
          href={process.env.REACT_APP_NODE_ENV === 'production' ? "https://enigmance.com/contact" : '/contact'}

          //  href="/contact"
          >
            Contact
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: { xs: 2, sm: 3 },
          width: '100%',
          // borderTop: '1px solid',
          // borderColor: 'divider',
        }}
      >
       <Copyright />
        </Box>
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection:'row',
          pt: { xs: 2, sm: 3 },
          width: '100%',
          // borderTop: '1px solid',
          // borderColor: 'divider',
        }}
      >
        
          <IconButton
            color="inherit"
            href="https://www.facebook.com/enigmAnce"
            aria-label="facebook"
            target="_blank"
            sx={{ alignSelf: 'center' }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/enigmAncestudio"
            aria-label="X"
            target="_blank"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://instagram.com/enigmAnce"
            aria-label="Instagram"
            target="_blank"
            sx={{ alignSelf: 'center' }}
          >
            <Instagram />
          </IconButton>
          </Box>      
    </Container>
  );
}
