import React, { createContext, useState, useContext } from 'react';
import Proptypes from 'prop-types'


const ThemeContext = createContext();
const isDev = process.env.REACT_APP_NODE_ENV; 
// console.log(isDev)
export const DarkThemeProvider = React.memo(({ children }) => {
  const [mode, setMode] = useState(isDev === 'development' ? 'dark' : 'light');

 
  const toggleDarkMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };
    




  return (
    <ThemeContext.Provider value={{ 
        toggleDarkMode,
        mode,
        setMode
      }}>
      {children}
    </ThemeContext.Provider>
  );
});

const useDarkTheme = () => useContext(ThemeContext);

ThemeContext.propTypes = {
  children: Proptypes.node.isRequired,
}

export { ThemeContext, useDarkTheme };
